import React from "react"
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Button from "react-bootstrap/Button";
import "./share-your-story.css";
import shareYourStoryStyle from "./share-your-story.module.css"
import Img from "gatsby-image";
import globalStyle from "../styles/global.module.css";
import {Container} from "react-bootstrap";

const ShareYourStoryPage = ({data}) => {

  return (
    <Layout className="site-content">
      <Container className="story-post-container">
        <div>
          <h1 className={globalStyle.entryTitle}>Share Your Story</h1>
        </div>
        <div className={globalStyle.subTitle}>
          <p>How have you been impacted by lack of paid family leave? We’re collecting stories like the one below to highlight how desperately our country needs equal and adequate National Paid Family Leave. Have a story to share? Submit it below!</p>
        </div>
        <div className="shareMyStorySection">
          <Button className="btn btn-primary redirectTo" href="/share-my-story/" value="Website" role="button">Share My Story</Button>
        </div>

        <div className="storyPost col-md-12">
          <div className="col-md-4 leftSide">
            <div>
              <h2>- Elizabeth J.</h2>
            </div>
            <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid}></Img>
          </div>
          <div className="col-md-8 rightSide">
            <p>I worked literally up to the day my water broke to save as much paid time off (PTO) as possible to use. At the time, I worked as a nurse for a large children’s healthcare organization that did not provide additional paid maternity leave. Babies of course don’t follow birth plans, and I ended up with Pregnancy Induced Hypertension and a vacuum-assisted birth after 18 hours of labor, a little over four weeks early. As my husband’s company did not have paternity leave, he had to go back to work within days of the birth, leaving me home with my mother who was battling cancer to support me through birth injuries and a premature, very small baby.
            While I had FMLA and some paid time off days to leverage, managing weight for preemies on top of nursing challenges compound to make even the ten weeks I had too short (he was just able to fit into a wearable carrier by the time I got back to work). My status as an employee wasn’t fully protected- I lost tuition reimbursement privileges (was completing the last semester of my master’s degree) which was hurtful given my tenure at the facility of three years at the time.
            </p>
            <span>
              Thank you Elizabeth for sharing this story with Moms on Maternity. It is our stories that will move people into action.
            </span>
          </div>
          <div style={{clear: "both"}}></div>
        </div>

        <div className={[shareYourStoryStyle.bottomBanner + " bottomBanner"]}>
          <h4>What You Need to Know</h4>
          <p>Learn more about the current state of paid family leave in America and how you can help.</p>
          <Button className="btn btn-primary redirectTo" href="/fight-for-paid-family-leave/our-opportunity/" value="Website" role="button">Our Opportunity</Button>
        </div>

      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    allFile(filter: {relativePath: {regex: "/stories.*/"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
  }
`;

export default ShareYourStoryPage
